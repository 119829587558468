// color palette
$primary-color: #3B6064;

// bootstrap
$theme-colors: (
  "primary": $primary-color,
  "secondary": #87BBA2
);
@import "~bootstrap/scss/bootstrap";

html {
  background-color: #446e74;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}