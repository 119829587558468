// color scheme
$primary-color: #3B6064;
$secondary-color: #87BBA2;
$white: #f0eff4;
$black: #020202;

.App {
  height: 100vh;
  text-align: center;
  a {
    color: $white;
    text-decoration: none;
    transition: all ease .4s;
  }
  a:hover {
    color: $secondary-color;
  }
}

html {
  background: linear-gradient(lighten($black, 5%), $black);
}

body {
  background-color: transparent;
}

.page {
  height: 100vh;
  div.row {
    max-height: 100%;
    div.col {
      max-height: 100vh;
      overflow: scroll;
    }
  }
}

.row,
.col {
  margin: 0;
  padding: 0;
}

// navigation
#navcol {
  display: flex;
  height: 50vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

// homepage positioning
.homepage-section {
  display: flex;
  min-height: 100vh;
  padding: 2rem 0;
  overflow: scroll;
  .section-content {
    justify-content: center;
    align-self: center;
    max-width: 1200px;
  }
}
div.col, div.col-auto {
  display: flex;
  align-items: center;
  justify-content: center;
}

// homepage about section
#about .section-content {
  width: calc(min(800px, 100vw) - 5vw);
  max-width: min(800px, 100vw);
  padding: 2rem 3rem;
  
  // photo corners
  $photocorner-color: #87BBA2;
  background:
    linear-gradient(to right, $photocorner-color 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, $photocorner-color 4px, transparent 4px) 0 0,
    linear-gradient(to left, $photocorner-color 4px, transparent 4px) 100% 100%,
    linear-gradient(to top, $photocorner-color 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
}

// homepage projects section
#projects .section-content {

  h2 {
    margin: 0;
  }
  h3 {
    margin: 0 0 1rem 0;
  }

  .row {
    background-color: rgba(red($white), green($white), blue($white), 0.25);
    border-radius: 2rem;
    margin: 1rem 0;
    padding: 1.5rem 1rem;
    .row {
      background-color: transparent;
    }
  }

  .project-card {
    border: 2px solid $white;
    border-radius: 100%;
    position: relative;
    max-height: 150px;
    max-width: 150px;
    img {
      height: 100%;
      width: 100%;
    }
    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background-color: #000000b0;
      opacity: 0;
      padding: 1rem;
      transition: all ease .4s;
      cursor: pointer;
      * {
        margin: 0;
        padding: 0;
      }
    }
    .overlay:hover {
      opacity: 1;
    }
  }

  // skills section
  .skills-container {
    // background-color: rgba(red($white), green($white), blue($white), 0.25);
    border-radius: 2rem;
    padding: 1rem;
    align-self: baseline;
    .row {
      padding: 0;
      margin: 0;
    }
    img {
      background-color: rgba(red($white), green($white), blue($white), 0.5);
      border-radius: 0.75rem;
      margin: 0.5rem;
    }
  }

}

// homepage resume section
#experience .section-content {
  padding: 1rem;

  a > h4:hover {
    text-decoration: underline;
    color: $primary-color;
  }

  h2 {
    margin-bottom: 1.5rem;
  }

  ul {
    margin: 0;
  }

  ul li {
    text-align: left;
    padding: 0.25rem 0;
  }

  ul li:last-child {
    padding: 0.25rem 0 0 0;
  }

  .row {
    justify-content: center;
  }

  .resume-container {
    background-color: rgba(red($white), green($white), blue($white), 0.25);
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    padding: 2rem;
  }

  .prof-exp-container {
    text-align: left;
    h5, p {
      margin-bottom: 0;
    }
    .row {
      display: flex;
      justify-content: center;
    }
    .row:first-child {
      min-height: 100px;
    }
  }
  
  .educ-container {
    padding-bottom: 1.5rem;
    .col {
      align-items: baseline;
    }
    .educ-logo {
      height: 125px;
      width: 125px;
    }
    .educ-logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 125px;
      width: 125px;
      margin: auto;
      background-color: rgba(red($white), green($white), blue($white), 0.85);
      border-radius: 100%;
    }
    p {
      margin-bottom: 0;
    }
    h4 {
      padding-top: 0.5rem;
    }
  }
}

// homepage media section
#contributions {
  padding: 2rem 0;
  .card {
    background-color: rgba(red($primary-color), green($primary-color), blue($primary-color), 0.5);
    border-radius: 1rem;
    text-align: left;
  }
  .media-container {
    align-self: baseline;
    background-color: rgba(red($white), green($white), blue($white), 0.25);
    border-radius: 2rem;
    padding: 2rem;
    margin: 1rem 0;
    h2 {
      margin-bottom: 0;
    }
    h3 {
      margin-bottom: 1.5rem;
    }
  }
}

// homepage contact section
#contact {
  .contact-container {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    background-color: rgba(red($white), green($white), blue($white), 0.25);
    border-radius: 2rem;
    margin: 0.5rem auto;
    padding: 1.5rem;
    width: 80vw;
    max-width: 800px;
  }
  form {
    padding: 0rem 1rem 0.5rem 1rem;
    .row {
      margin-top: 1rem;
    }
    .form-control, .button {
      border-radius: 0.5rem;
    }
    .form-control:focus {
      border-color: $primary-color;
      box-shadow: 0 0 0 0.25rem rgba(red($primary-color), green($primary-color), blue($primary-color), 0.5);
    }
  }
}

// homepage section colors
#about {
  background: linear-gradient(lighten($black, 5%), $black);
}
#projects {
  background: linear-gradient(#364958, darken(#3B6064, 5%));
}
#experience {
  background-color: darken(#3B6064, 5%);
}
#contributions {
  background: linear-gradient(darken(#3B6064, 5%), #364958);
}
#contact {
  background: linear-gradient(#364958, lighten(#3B6064, 5%));
}

// NAV BAR
nav {
  background: linear-gradient($primary-color, $primary-color, $primary-color, transparent);
}
nav .container {
  z-index: 1;
}
.nav-fade {
  background: linear-gradient($primary-color, transparent);
  height: 2rem;
  width: 100vw;
  position: absolute;
  bottom: -0.5rem;
  z-index: 0;
}

// PROJECTS
div.project-card-large {
  background-color: rgba(red($white), green($white), blue($white), 0.45);
  border-radius: 2rem;
  :hover {
    cursor: pointer;
  }
  img {
    border-radius: 1rem !important;
  }
}

// PROJECT DETAILS
div.project-details {
  padding: 1rem;
  h3 {
    color: $black;
  }
}
div.project-details h2 {
  background-color: rgba(red($secondary-color), green($secondary-color), blue($secondary-color), 0.35);
  border-radius: 1rem;
  margin: 0 0 1.5rem 0;
  padding: 1rem;
  top: 0;
}
.project-details-container {
  background-color: rgba(red($white), green($white), blue($white), 0.5);
  border-radius: 2rem;
  margin: 1rem 0;
  padding: 1rem;
}
div.readme {
  background-color: rgba(red($white), green($white), blue($white), 0.5);
  border-radius: 1rem;
  margin-bottom: 5rem;
  padding: 2rem;
  text-align: left;
}

// fonts
h1, h2, h3, h4 {
  font-family: 'Fira Mono', monospace;
  color: rgb(240, 239, 244);
}
* {
  font-family: 'Montserrat', sans-serif;
  color: #f0eff4;
  // font-size: 14pt;
}


#footer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 25vh;
  .row {
    width: 100%;
    .col {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.circle-button {
  border-radius: 100%;
  color: $white;
}